/** Responsive **/
@media screen and (max-width: 1450px) {
  #section-introduction {
    .fp-overflow {
      .MuiGrid-container {
        width: 80%;
        margin: {
          left: auto;
          right: auto;
        }
      }
    }
  }
  .project-section {
    h1 {
      font: {
        size: 2rem;
      }
    }
    p {
      font: {
        size: 1rem;
      }
    }
    width: 80%;
  }
  #others-projets-section {
    text-align:center;
  }
}

@media screen and (max-width: 700px) {
  #section-introduction {
    .fp-overflow {
      .MuiGrid-container {
        margin: {
          top: 7rem;
        }
      }
    }
  }
  .fp-next, .fp-prev {
    top: 100% !important;
    transform: translateY(-100%) !important;
  }
  .fp-next {
    right: 20px !important;
  }
  .project-section {
    width: 80%;
    margin: auto;
  }
}

@media screen and (max-width: 599px) {
  .my-services-card {
    margin: auto;
    width: 80%;
  }
  #title-mes-services {
    font: {
      size: 3rem !important;
    }
    margin: {
      top: 12rem !important;
    }
    width: 85%;
    margin: auto;
  }
  #credits-block, #title-credits {
    width: 80%;
    margin: auto;
  }
  #contact-us-card {
    margin: {
      top: 12rem;
    }
  }
}

@media screen and (max-width: 500px) {
  .section-title {
    font-size: 3rem !important;
  }
}