@import url('https://fonts.googleapis.com/css?family=Montserrat');

/** Offset right arrow **/
.fp-next {
  right: 60px !important;
}

h1 {
  font-size: 4em;
  -webkit-text-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.4);
  text-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.4);
  letter-spacing: -3px;
  color: #fff;
}

.intro p:not(.contact-form-introduction):not(.card-text) {
  color: #fff;
}

#title-mes-services {
  text-align: center;
}

#slide1-1 h1 {
  color: #444;
}

#section4 .fp-bg:before, #section2 .fp-bg:before {
  content: '';
  position: absolute;
  display: block;
  background: rgba(173, 173, 173, 0.2);
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.fp-bg {
  background-size: cover;
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;

  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}

/** Offset left arrow **/
.fp-prev {
  right: 65px;
}

body {
  font-family: 'Montserrat';
}

.section {
  min-height: 100vh !important;
}

#menu {
  li {
    display: inline-block;
    margin: 10px;
    color: #000;
    background: #fff;
    background: rgba(255, 255, 255, 0.5);
    -webkit-border-radius: 10px;
    border-radius: 10px;

    &.active {
      background: #666;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
    }

    a {
      text-decoration: none;
      color: #000;
    }

    &.active a:hover {
      color: #000;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.8);
    }

    a {
      padding: 9px 18px;
      display: block;
    }

    &.active a {
      padding: 9px 18px;
      display: block;
      color: #fff;
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  z-index: 70;
  width: 100%;
  padding: 0;
  margin: 0;
}

#section-introduction .fp-bg {
  background-image: url('../public/images/introduction-bg.jpg');
  filter: brightness(60%);
}

#section-credits .fp-bg {
  background-image: url('../public/images/credits-bg.jpg');
}

#slide-1 .fp-bg {
  background-image: url('../public/images/hydraulic-bg.jpg');
  filter: brightness(60%);
}

#slide-2 .fp-bg {
  background-image: url('../public/images/internal-project-bg.jpg');
  filter: brightness(60%);
}

#slide-3 .fp-bg {
  background-image: url('../public/images/mutual-bg.jpg');
  filter: brightness(60%);
}

#slide-4 .fp-bg {
  background-image: url('../public/images/defense-bg.jpg');
  filter: brightness(60%);
}

#slide-5 .fp-bg {
  background-image: url('../public/images/raspberry-bg.jpg');
  filter: brightness(60%);
}

#slide-6 .fp-bg {
  background: #000;
}
#section2 h1 {
  color: #fff;
}

#section-mes-services .fp-bg {
  background-image: url('../public/images/my-services-bg.jpg');
  background-position: 50% 65%;
  filter: brightness(90%);
}

#section-mes-projets .fp-bg {
  background-image: url('../public/images/my-projects-bg.jpg');
}

#section-temoignages .fp-bg {
  background-image: url('../public/images/neutral-bg.svg');
}

#section-contact .fp-bg {
  background-image: url('../public/images/contact-bg.jpg');
}

.textfield-contact-form {
  background: #2d2d2d;
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

#contact-us-card {
  max-width: 450px;
  padding: 15px;
  margin: 0px auto;
  margin-left: 0;
  border-radius: 8px;
  border: 0.5px solid #2d2d2d;
}

#introduction-block {
  text-align: initial;
}

.my-services-card {
  min-height: 400px;
}

@import 'scss/responsive.scss';
